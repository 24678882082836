
div#paper-title{
  color:black;
	font-size:36px;
	font-weight:700;
	text-shadow: 0.1em 0.1em 0.25em #aaa;
	padding-top:0.5em;
}

div#venue{
	color:#ddd;
	font-size:18px;
	font-weight:700;
	padding-bottom:1em;
	text-align:center;
	text-shadow: 0.1em 0.1em 0.1em #aaa;
}
div#venue b{
	color:black;
	font-variant:small-caps;
}

div#author-list{
	font-size: 18px;
	padding: 0.5em;
	text-align:center;
	color:black;
}

div#author-list a{
	font-size: 18px;
	margin: 0.5em;
	text-align:center;
	text-shadow: 0.1em 0.1em 0.25em #aaa;
}

div#affiliation{
	width: 900px;
	margin:auto;
	font-size:16px;
	padding-bottom:2em;
	text-align:center;
	color:black;
	text-shadow: 0.05em 0.05em 0.05em #aaa;
}

span.affiliation{
	padding: 1em;
}

img.teaser{
	width: 800px;
}

img.icon{
	width:28px;
	margin-bottom:-0.4em;
}

p.caption{
	font-size:15px;
	font-style:italic;
}

p.download a{
	font-weight:700;
	font-size:18px;
}

p.bibtex{
	font-size:12px;
}


button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 100%;
  font-size: 18px;
}
