.simple-social-icons ul li a,.simple-social-icons ul li a:hover{background-color:#fff !important;border-radius:25px;color:#000 !important;border:0 #fff solid !important;font-size:13px;padding:7px}.simple-social-icons ul li a:hover{background-color:#fff !important;border-color:#fff !important;color:#f7a27f !important}


.container {
  position:inherit;
  height: 100%;
  width: 100%;
  padding: 20px 0px;
}

.card {
	display: flex;
	height: 232px;
	width: 153px;
	border-style: solid;
	background-color: #F2F2F2;
	border-width:thick;
	border-radius: 30px;
	border-color: #000000;
	box-shadow: -1px rem 0px 3 rem #000;
	/*   margin-left: -50px; */
	transition: 0.4s ease-out;
	position: relative;
	left: 0px;
	float: left;
}

.profile{
	position: absolute;
	top: 0px;
	width: 100%;
	height: 70%;
	left: 0px;
	object-fit: cover;
}

.info{
	position: absolute;
	top: 155px;
	width: 100%;
	height: 30%;
	background-color: #D7D9E3;
	border-bottom-left-radius: 23px;
	border-bottom-right-radius: 23px;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

.card:not(:first-child) {
    margin-left: -10px;
}

.card:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.card:hover ~ .card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.title {
	color: white;
	width: 100%;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 1vw;
	font-size: 14px;
	position: absolute;
	top: 40px;
	text-align: center;
}

.name {
	color: black;
	width: 100%;
	font-family: "Arial Black", Gadget, sans-serif;
	font-size: 1vw;
	font-size: 14px;
	position: absolute;
	top: 10px;
	text-align: center;
}

.bar {
  position: absolute;
  top: 0px;
  left: 20px;
  height: 5px;
  width: 150px;
}

.emptybar {
  background-color: #2e3033;
  width: 100%;
  height: 100%;
}

.filledbar {
  position: absolute;
  top: 0px;
  z-index: 3;
  width: 0px;
  height: 100%;
  background: rgb(0,154,217);
  background: linear-gradient(90deg, rgba(0,154,217,1) 0%, rgba(217,147,0,1) 65%, rgba(255,186,0,1) 100%);
  transition: 0.6s ease-out;
}

.card:hover .filledbar {
  width: 120px;
  transition: 0.4s ease-out;
}

.circle {
  position: absolute;
  top: 150px;
  left: calc(50% - 60px);
}

.stroke {
  stroke: white;
  stroke-dasharray: 360;
  stroke-dashoffset: 360;
  transition: 0.6s ease-out;
}

svg {
  fill: #17141d;
  stroke-width: 2px;
}

.card:hover .stroke {
  stroke-dashoffset: 100;
  transition: 0.6s ease-out;
}