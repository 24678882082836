$primary:       #0064a4;
$link:          #666666;
$link-hover:    #999999;

$white:         #ffffff;
$black:         #000000;
$lightred: #cc3c3c;

.yearGroup {
  font-size: 20px;
}

.papers {
  padding-left: 5px;
  margin: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: medium;
}

// .papers li {
//   margin: 10px;
//   padding-left: 0px;
// }

.mainAuthor {
  color:$primary;
  font-weight: normal;
}

.mainAuthor a {
  color:$primary;
  font-weight: bold;
}

.paper.title {
  font-weight: bold;
  display: block;
}

.venue {
  font-style: italic;
}

.emphasisNote {
  font-weight: bold;
  color: $primary;
}

.note {
  font-style: italic;
  color: $primary;
}

.abstract {
  display: none;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.tag {
  margin-left: 10px;
  padding: 3px;
}

.collapse {
    display: none;
}

.details-panel {
  margin: 2px;
  padding: 2px;
  font-size: 12px;
}

.tag_Conference {
  background-color: $primary;
  color: $white;
  border-radius: 10px;
}

.tag_Journal {
  background-color: $lightred;
  color: $white;
  border-radius: 10px;
}

.tag_Workshop {
  background-color: $link;
  color: $white;
  border-radius: 10px;
}

.tag_Chapter {
  background-color: $link-hover;
  color: $black;
  border-radius: 10px;
}

.tag_Demo {
  background-color: $link-hover;
  color: $black;
  border-radius: 10px;
}
.tag_Preprint {
  background-color: $link-hover;
  color: $black;
  border-radius: 10px;
}


/* .link a {
  display: inline-block;
} */


/* .details-panel-body {
  margin: 0px;
  border: 0px;
  padding: 10px;
  font-size: 12px;
}

.details-panel-body:before {
  content: "";
}

.details-panel-body:after {
  content: "";
} */